import { Component, HostBinding, OnInit, OnDestroy, Inject, PLATFORM_ID } from '@angular/core';
import {
	GuardsCheckEnd,
	GuardsCheckStart,
	NavigationCancel,
	NavigationEnd,
	NavigationError,
	NavigationStart,
	ResolveEnd,
	ResolveStart,
	Router,
} from '@angular/router';
import { Angulartics2GoogleTagManager } from 'angulartics2/gtm';

import { Subscription } from 'rxjs/Subscription';
import { isPlatformBrowser } from '@angular/common';
import { SharedStore } from '@app/core/services/shared';



@Component({
	// tslint:disable-next-line:component-selector
	selector:		'app',
	templateUrl:	'app.component.html',
})
export class AppComponent implements OnInit, OnDestroy {
	@HostBinding('class') public cssClass = 'home';

	private popupOpenSubscription: Subscription;
	private popupCloseSubscription: Subscription;

	constructor(
		public shared: SharedStore,
		router: Router,
		angulartics2GoogleTagManager: Angulartics2GoogleTagManager,
		@Inject(PLATFORM_ID) private platformId: object,
	) {
		angulartics2GoogleTagManager.startTracking();
	}

	private _addPassiveTouchStartListenerToBody() {
		// Test via a getter in the options object to see if the passive property is accessed
		let supportsPassive = false;
		try {
			const opts = Object.defineProperty({}, 'passive', {
				get: () => {
					supportsPassive = true;
				}
			});
			window.addEventListener('testPassive', null, opts);
			window.removeEventListener('testPassive', null, opts);
		} catch (e) {
			//
		}
		// Use our detect's results. passive applied if supported, capture will be false either way.
		document.body.addEventListener('touchstart', () => void 0, supportsPassive ? { passive: true } : false);
	}

	ngOnInit() {
		if (isPlatformBrowser(this.platformId)) {
			this._addPassiveTouchStartListenerToBody();

			// cookie consent popup
			const bodyPaddingBottom = document.body.style.paddingBottom || '0px';
		}
	}

	ngOnDestroy() {
		if (this.popupOpenSubscription) this.popupOpenSubscription.unsubscribe();
		if (this.popupCloseSubscription) this.popupCloseSubscription.unsubscribe();
		// this.initializeSubscription.unsubscribe();
	}
}
