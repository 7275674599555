import { NgModule } from '@angular/core';
import { SHARED_COMPONENTS, SHARED_ENTRY_COMPONENTS } from './components';
import { SHARED_DIRECTIVES } from './directives';
import { SHARED_PIPES } from './pipes';
import { MODAL_COMPONENTS } from './modals';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModalModule, NgbCollapseModule, NgbDropdownModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faCircle, faMapMarkerAlt, faBadgeCheck } from '@fortawesome/pro-solid-svg-icons';
import { faChevronLeft, faChevronRight, faTimes, faUpload, faTrashAlt, faClock, faSadTear } from '@fortawesome/pro-light-svg-icons';
import { faInstagram, faFacebookF, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { SwiperModule } from 'ngx-swiper-wrapper';
// import { ObserversModule } from '@angular/cdk/observers';
import { InlineSVGModule } from 'ng-inline-svg';
import { NgSelectModule } from '@ng-select/ng-select';
import { FileUploadModule } from '@iplab/ngx-file-upload';
import { NgInviewModule } from 'angular-inport';
import { CMSModule } from '@app/cms';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		RouterModule,
		ReactiveFormsModule,
		// ObserversModule,
		FontAwesomeModule,
		SwiperModule,
		InlineSVGModule,
		FileUploadModule,
		NgInviewModule,
		NgSelectModule,

		NgbModalModule,
		NgbDropdownModule,
		NgbCollapseModule,
		NgbTooltipModule,

		CMSModule,
	],
	declarations: [
		...SHARED_COMPONENTS,
		...SHARED_DIRECTIVES,
		...SHARED_PIPES,
		...MODAL_COMPONENTS
	],
	exports: [
		...SHARED_COMPONENTS,
		...SHARED_DIRECTIVES,
		...SHARED_PIPES,

		// ObserversModule,

		NgbModalModule,
		NgbCollapseModule,
		NgbDropdownModule,
		NgbTooltipModule,
		FontAwesomeModule,
		SwiperModule,
		InlineSVGModule,
		FileUploadModule,
		NgInviewModule,

		CMSModule,
	],
	entryComponents: [
		...SHARED_ENTRY_COMPONENTS,
		...MODAL_COMPONENTS,
	]
})
export class SharedModule {
	constructor(library: FaIconLibrary) {
		library.addIcons(faCircle, faMapMarkerAlt, faChevronLeft, faChevronRight, faInstagram, faFacebookF, faYoutube, faTimes, faUpload, faTrashAlt, faClock, faSadTear, faBadgeCheck);
	}
}
