import { Component } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { HttpParams, HttpClient } from '@angular/common/http';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MessageModalComponent, MessageModalParams } from '@app-shared/modals';

interface MailChimpResponse {
	result: string;
	msg: string;
}

@Component({
	selector: 'section[top-footer]',
	templateUrl: 'top-footer.component.html',
	styleUrls: [ 'top-footer.component.scss' ],
})
export class TopFooterComponent {
	public formProgress: boolean = false;
	public newsletterForm = new FormGroup({
		email: new FormControl('', [
			Validators.email,
			Validators.required
		])
	});

	constructor(
		private _http: HttpClient,
		private _modal: NgbModal,
	) {
		//
	}

	public sendNewsletter() {
		console.log('sendNewsletter', this.newsletterForm.value);
		this.formProgress = true;

		this._mailchimpSubscribe(this.newsletterForm.value)
		.then((data) => {
			console.log(data);
			this.formProgress = false;
			if (data.result === 'success') {
				this.newsletterForm.reset();
			}

			this.showMessageModal({
				title: data.result === 'success' ? 'Hírlevél feliratkozás' : 'Hiba',
				message: data.result === 'success' ? '<p>Köszönjük, hamarosan egy emailt fogsz kapni, amiben meg kell erősítened a hírlevél feliratkozást!</p>' : '<p>Jól adtad meg az email címedet? De az is lehet, hogy hiba történt a hírlevél feliratkozás közben. Kérlek, próbáld újra!</p>',
			});
		});
	}

	public showMessageModal({ title, message }: MessageModalParams): Promise<any> {
		const modal = this._modal.open(MessageModalComponent);
		modal.componentInstance.title = title;
		modal.componentInstance.message = message;

		return modal.result;
	}

	private _mailchimpSubscribe(data: { email: string }) {
		const endpoint = 'https://siogyumolcs.us15.list-manage.com/subscribe/post-json';

		const params = new HttpParams()
			.set('id', '6d70e799a6')
			.set('u', '76c5f09f5de5430a3157fd48c')
			.set('username', 'siogyumolcs')
			.set('EMAIL', data.email)
		;

		const mailChimpUrl = `${endpoint}?${params.toString()}`;

		return this._http.jsonp<MailChimpResponse>(mailChimpUrl, 'c').toPromise();
	}
}
