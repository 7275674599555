import { Action } from '@ngrx/store';

import { PartialEntity, EntityType, EntityLanguage, EntitySlug, Entity, Filter, Dictionary, Pagination } from '@app/cms/models/entity.model';

export const POSTS_LOAD = '[Posts] load';
export const POSTS_LOAD_SUCCESS = '[Posts] load success';
export const POSTS_LOAD_FAILURE = '[Posts] load failure';

export const POSTS_SET_FILTER = '[Posts] set filter';

export const POST_LOAD = '[Post details] load';
export const POST_LOAD_SUCCESS = '[Post details] load success';
export const POST_LOAD_FAILURE = '[Post details] load failure';

export class PostsLoadAction implements Action {
	public readonly type = POSTS_LOAD;
	constructor(public payload: { collection: string, page: number, count?: number }) {}
}

export class PostsLoadSuccessAction implements Action {
	public readonly type = POSTS_LOAD_SUCCESS;
	constructor(public payload: { collection: string, items: PartialEntity<Entity>[], pagination: Pagination }) {}
}

export class PostsLoadFailureAction implements Action {
	public readonly type = POSTS_LOAD_FAILURE;
	constructor(public payload: { collection: string }) {}
}

export class PostsSetFilterAction implements Action {
	public readonly type = POSTS_SET_FILTER;
	constructor(public payload: { collection: string, filter: Filter }) {}
}

export class PostLoadAction implements Action {
	public readonly type = POST_LOAD;
	constructor(public payload: { slug: EntitySlug, type?: EntityType, language?: EntityLanguage, options?: Dictionary<string> }) {}
}

export class PostLoadSuccessAction implements Action {
	public readonly type = POST_LOAD_SUCCESS;
	constructor(public payload: { post: PartialEntity<Entity> }) {}
}

export class PostLoadFailureAction implements Action {
	public readonly type = POST_LOAD_FAILURE;
	constructor(public payload: { slug: EntitySlug, type?: EntityType, language?: EntityLanguage }) {}
}

export type PostActions
	= PostsLoadAction
	| PostsLoadSuccessAction
	| PostsLoadFailureAction
	| PostsSetFilterAction
	| PostLoadAction
	| PostLoadSuccessAction
	| PostLoadFailureAction
;
