import * as tslib_1 from "tslib";
import { select } from '@ngrx/store';
import * as fromCMS from '../store';
import { filter, take, first } from 'rxjs/operators';
import { PAGE_NEXT, PAGE_PREV, DEFAULT_ENTITY_COLLECTION, DEFAULT_ENTITY_TYPE } from '../models/entity.model';
export const RELOAD_COLLECTION = true;
// @Injectable({
// 	providedIn: CMSModule
// })
export class CMSService {
    constructor(_store, _meta, _router) {
        this._store = _store;
        this._meta = _meta;
        this._router = _router;
    }
    // SITE
    _loadSite() {
        this._store.select(fromCMS.isSiteLoadedOrLoading).pipe(take(1)).subscribe(isLoadedOrLoading => {
            if (!isLoadedOrLoading) {
                this._store.dispatch(new fromCMS.SiteLoadAction());
            }
        });
    }
    getSiteConfigGroup(group) {
        this._loadSite();
        return this._store.select(fromCMS.getSiteConfigGroup(group));
    }
    getOptions() {
        this._loadSite();
        return this._store.select(fromCMS.getOptions);
    }
    getMenu(location) {
        this._loadSite();
        return this._store.select(fromCMS.getMenuConfig(location));
    }
    getTags() {
        this._loadSite();
        return this._store.select(fromCMS.getTags);
    }
    getCategories() {
        this._loadSite();
        return this._store.pipe(select(fromCMS.getCategories), filter(categories => categories.length > 0));
    }
    // POSTS
    _loadPosts(collection, page = PAGE_NEXT, count) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            // console.log('CMS _loadPosts', collection, page);
            if (page === PAGE_NEXT || page === PAGE_PREV) {
                let current_page = 0;
                this._store.select(fromCMS.getPostsPagination(collection)).pipe(first()).subscribe(pagination => {
                    if (typeof pagination !== 'undefined') {
                        current_page = pagination.current_page;
                    }
                });
                if (page === PAGE_NEXT)
                    page = current_page + 1;
                if (page === PAGE_PREV)
                    page = current_page - 1;
            }
            this._store.dispatch(new fromCMS.PostsLoadAction({ collection, page, count }));
        });
    }
    loadPosts(collection = DEFAULT_ENTITY_COLLECTION, page = PAGE_NEXT) {
        // console.log('CMS loadPostCollection', collection, page);
        this._loadPosts(collection, page);
    }
    setPostsFilter(collection = DEFAULT_ENTITY_COLLECTION, filter = {}, updateCollection = false) {
        // console.log('CMS setPostCollectionFilter', collection, filter, updateCollection);
        this._store.dispatch(new fromCMS.PostsSetFilterAction({ collection, filter }));
        if (updateCollection)
            this.loadPosts(collection, 1);
    }
    getPosts(collection = DEFAULT_ENTITY_COLLECTION, page = PAGE_NEXT, options) {
        // console.log('CMS getPosts', collection, options);
        options = Object.assign({ loadPostsImmediately: false, forceReload: false }, options);
        let shouldLoad = options.forceReload;
        this._store.select(fromCMS.getPostsLoaderState(collection)).pipe(first()).subscribe(loaderState => {
            shouldLoad = shouldLoad || (options.loadPostsImmediately && (loaderState.failed || !(loaderState.loading || loaderState.loaded)));
        });
        if (shouldLoad) {
            if (options.filter)
                this.setPostsFilter(collection, options.filter);
            this.loadPosts(collection, page);
        }
        return this._store.select(fromCMS.getPosts(collection));
    }
    getPostsLoaderState(collection = DEFAULT_ENTITY_COLLECTION) {
        return this._store.select(fromCMS.getPostsLoaderState(collection));
    }
    getPostsPagination(collection = DEFAULT_ENTITY_COLLECTION) {
        return this._store.select(fromCMS.getPostsPagination(collection));
    }
    // POST
    _loadPost(slug, filter, options) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const type = Array.isArray(filter.type) ? filter.type[0] : filter.type;
            const language = (Array.isArray(filter.language) ? filter.language[0] : filter.language) || undefined;
            if (!(yield this._store.select(fromCMS.isPostLoadedorLoading(slug, type, language)).pipe(first()).toPromise())) {
                this._store.dispatch(new fromCMS.PostLoadAction({ slug, type, language, options }));
            }
        });
    }
    getPost(slug, arg, options = {}) {
        let entityFilter = { type: DEFAULT_ENTITY_TYPE };
        if (typeof arg === 'string') {
            entityFilter = Object.assign({}, entityFilter, { type: arg });
        }
        else if (typeof arg === 'object') {
            entityFilter = Object.assign({}, entityFilter, arg);
        }
        this._loadPost(slug, entityFilter, options);
        const type = Array.isArray(entityFilter.type) ? entityFilter.type[0] : entityFilter.type;
        const language = (Array.isArray(entityFilter.language) ? entityFilter.language[0] : entityFilter.language) || undefined;
        return this._store.pipe(select(fromCMS.getPost(slug, type, language)), filter(post => typeof post !== 'undefined'));
    }
}
