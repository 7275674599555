import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { SharedModule } from '@app/shared/shared.module';
import { NotFoundComponent } from '@app/shared/components';

const appRoutes: Routes = [
	{
		path: 'nyeremenyjatek',
		// pathMatch: 'full',
		loadChildren: () => import('./ncp/ncp.module').then(m => m.NCPModule),
	},
	{
		path: '',
		// pathMatch: 'full',
		loadChildren: () => import('./home/home.module').then(m => m.HomeModule)
	},
	{
		path: '**',
		component: NotFoundComponent,
	},
];

@NgModule({
	imports: [
		RouterModule.forRoot(appRoutes, {
			useHash: false,
			initialNavigation: 'enabled',
			anchorScrolling: 'enabled',
			scrollPositionRestoration: 'top',
			// enableTracing: true
		}),

		SharedModule
	],
	exports: [
		RouterModule
	],
	providers: [
	]
})

export class AppRoutingModule {}
