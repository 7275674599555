import { Injectable } from '@angular/core';
import { Observable, of, defer } from 'rxjs';
import { switchMap, catchError, map } from 'rxjs/operators';

import { Action } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';

import { SiteAPIService } from '../../services/site.service';
import * as SiteActions from '../actions/site.actions';
// import * as Sentry from '@sentry/browser';


@Injectable()
export class SiteEffects {

	constructor(
		private actions$: Actions,
		private siteService: SiteAPIService,
	) {}

	@Effect()
	loadSite$: Observable<Action> = this.actions$.pipe(
		ofType(SiteActions.SITE_LOAD),
		switchMap(() => {
			return this.siteService.getSite().pipe(
				map(siteConfig => new SiteActions.SiteLoadSuccessAction(siteConfig)),
				catchError(error => {
					// Sentry.withScope(scope => {
					// 	scope.setTag('module', 'site');
					// 	scope.setExtra('error', error);
					// 	Sentry.captureMessage('CMS API error');
					// });
					return of(new SiteActions.SiteLoadFailureAction());
				})
			);
		})
	);

	@Effect()
	init$: Observable<Action> = defer(() => {
		console.log('SiteEffects: init$');
		return of(new SiteActions.SiteLoadAction());
	});
}
