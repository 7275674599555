import { Component, HostListener } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ContactModalComponent } from '@app-shared/modals';

@Component({
	selector: 'header',
	templateUrl: 'header.component.html',
	styleUrls: ['header.component.scss'],
})
export class HeaderComponent {
	public isNavbarCollapsed: boolean = true;
	public scroll: boolean = false;

	constructor(
		router: Router,
		private _modal: NgbModal,
	) {
		router.events.subscribe((event) => {
			if (event instanceof NavigationEnd) {
				this.isNavbarCollapsed = true;
			}
		});
	}

	@HostListener('window:scroll', [])
	public onWindowScroll() {
		this.scroll = window.pageYOffset >= 100;
	}

	public showContactModal(): Promise<any> {
		const modal = this._modal.open(ContactModalComponent);
		return modal.result;
	}
}
