import * as tslib_1 from "tslib";
import { Effect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import * as PostActions from '@app/cms/store/actions/post.actions';
import { switchMap, mergeMap, catchError, map, first } from 'rxjs/operators';
import * as Sentry from '@sentry/minimal';
import { getPostsFilter } from '@app/cms/store/selectors';
// import { tag } from 'rxjs-spy/operators/tag';
export class PostEffects {
    constructor(actions$, store$, postsAPIService) {
        this.actions$ = actions$;
        this.store$ = store$;
        this.postsAPIService = postsAPIService;
        // @Effect()
        // setFilter$: Observable<Action> = this.actions$
        // 	.ofType(PostActions.POSTS_SET_FILTER).pipe(
        // 		tap(x => console.log('effect setFilter$ payload = ', x)),
        // 		map((action: PostActions.PostsSetFilterAction) => action.payload.collection),
        // 		mergeMap(collection => [
        // 			new PostActions.PostsLoadAction(collection),
        // 		]),
        // 	);
        this.loadPosts$ = this.actions$.pipe(ofType(PostActions.POSTS_LOAD), map((action) => action.payload), mergeMap(({ collection, page, count = null }) => this.store$.select(getPostsFilter(collection)).pipe(first(), map(filter => ({ collection, filter, page, count })))), mergeMap(({ collection, filter, page, count }) => {
            return this.postsAPIService.getPosts({ filter, page, count }).pipe(mergeMap(result => [
                new PostActions.PostsLoadSuccessAction(Object.assign({}, result, { collection })),
            ]), catchError(error => {
                console.log('effect error', error);
                Sentry.withScope(scope => {
                    scope.setTag('module', 'posts');
                    scope.setExtra('error', error);
                    scope.setExtra('collection', collection);
                    scope.setExtra('filter', filter);
                    scope.setExtra('page', page);
                    Sentry.captureMessage('CMS API error');
                });
                return of(new PostActions.PostsLoadFailureAction({ collection }));
            }));
        }));
        this.loadPostDetails$ = this.actions$.pipe(ofType(PostActions.POST_LOAD), map((action) => action.payload), switchMap(({ slug, type, language, options }) => {
            return this.postsAPIService.getPost(slug, type, language, options).pipe(mergeMap(post => [
                new PostActions.PostLoadSuccessAction({ post }),
            ]), catchError(error => {
                console.log('effect error', error);
                // Sentry.withScope(scope => {
                // 	scope.setTag('module', 'posts');
                // 	scope.setExtra('error', error);
                // 	Sentry.captureMessage('CMS API error');
                // });
                return of(new PostActions.PostLoadFailureAction({ slug, type, language }));
            }));
        }));
    }
}
tslib_1.__decorate([
    Effect()
], PostEffects.prototype, "loadPosts$", void 0);
tslib_1.__decorate([
    Effect()
], PostEffects.prototype, "loadPostDetails$", void 0);
