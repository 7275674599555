import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import mergeWith from 'lodash-es/mergeWith';

import * as actions from '../actions/post.actions';
import { EntityGeneratedId, generateEntityId, PartialEntity, LoaderState, EntityCollections, EntityCollection, Entity } from '@app/cms/models/entity.model';


export interface PostState extends PartialEntity<Entity> {
	_loaderState: LoaderState;
}

export const initialPostState: PostState = {
	__ID: undefined,
	// slug: undefined,
	_loaderState: {
		loaded: false,
		loading: false,
		failed: false,
		partiallyLoaded: false,
	}
};

export const adapter: EntityAdapter<PostState> = createEntityAdapter<PostState>({
	selectId: generateEntityId,
	sortComparer: false,
});

export interface State extends EntityState<PostState> {
	collections: EntityCollections;
}


export const initialCollectionState: EntityCollection = {
	pages: [],
	loadedEntities: [],
	_loaderState: {
		loaded: false,
		loading: false,
		failed: false,
	}
};


export const initialState: State = adapter.getInitialState({
	collections: {},
});


export function reducer(state = initialState, action: actions.PostActions): State {

	const mergeWithCustomizer = (objValue: any, srcValue: any) => {
		if (Array.isArray(objValue)) {
			// return objValue.concat(srcValue).filter((elem, pos, arr) => arr.indexOf(elem) === pos);
			return srcValue;
		}
	};
	const getPrevEntityState = (__ID: EntityGeneratedId) => state.entities[__ID] ? state.entities[__ID] : { ...initialPostState, __ID };
	const getPrevCollectionState = (collection: string) => state.collections[collection] ? state.collections[collection] : { ...initialCollectionState };
	const transformPost = (post: PartialEntity<Entity>): PartialEntity<Entity> => ({ ...post, __ID: generateEntityId(post) });

	switch (action.type) {

		case actions.POSTS_LOAD: {
			const { collection, page } = action.payload;
			return {
				...state,
				collections: {
					...state.collections,
					[collection]: {
						...getPrevCollectionState(collection),
						_loaderState: {
							...getPrevCollectionState(collection)._loaderState,
							loading: true,
						}
					},
				},
			};
		}

		case actions.POSTS_LOAD_SUCCESS: {
			const { collection, items, pagination } = action.payload;
			const posts = items.map(transformPost).map(
				(post) => mergeWith(
					{},
					getPrevEntityState(generateEntityId(post)),
					post,
					{
						_loaderState: {
							partiallyLoaded: true
						}
					},
					mergeWithCustomizer
				)
			);
			return adapter.upsertMany(posts, {
				...state,
				collections: {
					...state.collections,
					[collection]: {
						...getPrevCollectionState(collection),
						pages: {
							...getPrevCollectionState(collection).pages,
							[pagination.current_page]: posts.map(post => post.__ID),
						},
						loadedEntities: posts.map(post => post.__ID),
						pagination,
						_loaderState: {
							...getPrevCollectionState(collection)._loaderState,
							loaded: true,
							loading: false,
						}
					},
				},
			});
		}

		case actions.POSTS_LOAD_FAILURE: {
			// const { collection } = action.payload;
			return {
				...state,
				// collections: {
				// 	...state.collections,
				// 	[collection]: {
				// 		...getPrevCollectionState(collection),
				// 		_loaderState: {
				// 			...state.collections[collection]._loaderState,
				// 			loaded: true,
				// 			loading: false,
				// 		},
				// 	},
				// },
			};
		}

		case actions.POSTS_SET_FILTER: {
			const { collection, filter } = action.payload;
			return {
				...state,
				collections: {
					...state.collections,
					[collection]: {
						...initialCollectionState,
						filter,
						_loaderState: {
							failed: false,
							loaded: false,
							loading: false,
						},
					},
				},
			};
		}

		case actions.POST_LOAD: {
			// const { slug, type, language } = action.payload;
			// const post = mergeWith(
			// 	{ slug, type, language },
			// 	getPrevEntityState(generateEntityId(action.payload)),
			// 	{
			// 		_loaderState: {
			// 			loading: true
			// 		}
			// 	},
			// 	mergeWithCustomizer
			// );
			// return adapter.upsertOne(post, state);
			return state;
		}

		case actions.POST_LOAD_SUCCESS: {
			const post = mergeWith(
				{},
				getPrevEntityState(generateEntityId(action.payload.post)),
				transformPost(action.payload.post),
				{
					_loaderState: {
						loading: false, loaded: true, failed: false, partiallyLoaded: true
					}
				},
				mergeWithCustomizer
			);
			return adapter.upsertOne(post, state);
		}

		case actions.POST_LOAD_FAILURE: {
			const post = mergeWith(
				{},
				getPrevEntityState(generateEntityId(action.payload)),
				{
					_loaderState: {
						loading: false, failed: true
					}
				},
				mergeWithCustomizer
			);
			return adapter.upsertOne(post, state);
		}

		default:
			return state;
	}
}
