<div class="container align-items-center">
	<p>
		&copy; 2014-2022 SIÓ-ECKES Kft. Minden jog fenntartva.
	</p>
	<nav class="nav ml-auto">
		<a href="/assets/pdf/adatkezelesi_tajekoztato.pdf" target="_blank" class="nav-link">Adatkezelési tájékoztató</a>
		<a href="/assets/pdf/jogi_nyilatkozat.pdf" target="_blank" class="nav-link">Jogi nyilatkozat</a>
		<a href="/assets/pdf/cookie_tajekoztato.pdf" target="_blank" class="nav-link">Cookie tájékoztató</a>
	</nav>
</div>
