/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./shared/components/top-footer/top-footer.component.ngfactory";
import * as i2 from "./shared/components/top-footer/top-footer.component";
import * as i3 from "@angular/common/http";
import * as i4 from "@ng-bootstrap/ng-bootstrap";
import * as i5 from "./shared/components/header/header.component.ngfactory";
import * as i6 from "./shared/components/header/header.component";
import * as i7 from "@angular/router";
import * as i8 from "@angular/common";
import * as i9 from "./shared/components/footer/footer.component.ngfactory";
import * as i10 from "./shared/components/footer/footer.component";
import * as i11 from "./app.component";
import * as i12 from "./core/services/shared";
import * as i13 from "angulartics2/gtm";
var styles_AppComponent = [];
var RenderType_AppComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
function View_AppComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "section", [["top-footer", ""]], null, null, null, i1.View_TopFooterComponent_0, i1.RenderType_TopFooterComponent)), i0.ɵdid(1, 49152, null, 0, i2.TopFooterComponent, [i3.HttpClient, i4.NgbModal], null, null)], null, null); }
export function View_AppComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "header", [], null, [["window", "scroll"]], function (_v, en, $event) { var ad = true; if (("window:scroll" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).onWindowScroll() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_HeaderComponent_0, i5.RenderType_HeaderComponent)), i0.ɵdid(1, 49152, null, 0, i6.HeaderComponent, [i7.Router, i4.NgbModal], null, null), (_l()(), i0.ɵeld(2, 0, null, null, 2, "main", [], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i0.ɵdid(4, 212992, null, 0, i7.RouterOutlet, [i7.ChildrenOutletContexts, i0.ViewContainerRef, i0.ComponentFactoryResolver, [8, null], i0.ChangeDetectorRef], null, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AppComponent_1)), i0.ɵdid(6, 16384, null, 0, i8.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(7, 0, null, null, 1, "footer", [], null, null, null, i9.View_FooterComponent_0, i9.RenderType_FooterComponent)), i0.ɵdid(8, 49152, null, 0, i10.FooterComponent, [], null, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 4, 0); var currVal_0 = (_co.shared.page !== "thanks"); _ck(_v, 6, 0, currVal_0); }, null); }
export function View_AppComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app", [], [[8, "className", 0]], null, null, View_AppComponent_0, RenderType_AppComponent)), i0.ɵdid(1, 245760, null, 0, i11.AppComponent, [i12.SharedStore, i7.Router, i13.Angulartics2GoogleTagManager, i0.PLATFORM_ID], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).cssClass; _ck(_v, 0, 0, currVal_0); }); }
var AppComponentNgFactory = i0.ɵccf("app", i11.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
