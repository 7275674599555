import * as i0 from "@angular/core";
import * as i1 from "@angular/platform-browser";
// import { isResourceWithSEOData } from '@app/cms/models/wp-resource.model';
export class MetaService {
    constructor(title, meta) {
        this.title = title;
        this.meta = meta;
    }
    setMeta(state) {
        const meta = [];
        // if (isResourceWithSEOData(state)) {
        // 	Object.entries(state.fields.seo).map(([key, value]) => {
        // 		if (value !== null) {
        // 			switch (key) {
        // 				case 'title': {
        // 					this.title.setTitle(value);
        // 					break;
        // 				}
        // 				default: {
        // 					meta.push(
        // 						{
        // 							name: key,
        // 							content: value
        // 						}
        // 					);
        // 				}
        // 			}
        // 			meta.push({
        // 				property: 'og:' + key,
        // 				content: value
        // 			});
        // 		}
        // 	});
        // 	meta.push({
        // 		property: 'og:url',
        // 		content: 'https://eurocenterobuda.hu' + state.canonical_url
        // 	});
        // 	// console.log('setMeta', meta);
        // 	meta.forEach(tag => this.meta.updateTag(tag));
        // }
    }
}
MetaService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MetaService_Factory() { return new MetaService(i0.ɵɵinject(i1.Title), i0.ɵɵinject(i1.Meta)); }, token: MetaService, providedIn: "root" });
