import { createSelector } from '@ngrx/store';

import * as fromCMS from '@app/cms/store/reducers';
import * as fromPost from '@app/cms/store/reducers/post.reducers';
import { EntitySlug, EntityType, DEFAULT_ENTITY_TYPE, EntityLanguage, DEFAULT_ENTITY_LANGUAGE, generateEntityId, LoaderState, EntityId } from '@app/cms/models/entity.model';

export const {
	selectEntities: getPostEntities,
	selectAll: getAllPosts,
} = fromPost.adapter.getSelectors(fromCMS.getPostsState);



export const getPostsCollection = (collection: string) => createSelector(
	fromCMS.getPostsState,
	state => state.collections[collection] || fromPost.initialCollectionState
);

export const getPostsLoaderState = (collection: string) => createSelector(
	getPostsCollection(collection),
	collection => collection._loaderState
);

export const getPostIds = (collection: string, page: number) => createSelector(
	getPostsCollection(collection),
	collection => collection.pages[page] || []
);

export const getPostsPages = (collection: string) => createSelector(
	getPostsCollection(collection),
	collection => collection.pages
);

export const getPostsFilter = (collection: string) => createSelector(
	getPostsCollection(collection),
	collection => collection.filter
);

export const getPostsPagination = (collection: string) => createSelector(
	getPostsCollection(collection),
	collection => collection.pagination
);

export const getPostsPagesLoaded = (collection: string) => createSelector(
	getPostsCollection(collection),
	collection => Object.keys(collection.pages).length
);

export const getPosts = (collection: string, options: { allPages: boolean } = { allPages: true }) => createSelector(
	getPostEntities,
	getPostsPagination(collection),
	getPostsPages(collection),
	(entities, pagination, pages) => {
		let ids = [];
		if (pagination) {
			ids = options.allPages ? Object.values(pages).reduce((acc, val) => acc.concat(val), []) : pages[pagination.current_page];
		}
		// console.log(ids);
		return ids.map(id => entities[id]);
	}
);

export const getPost = (idOrSlug: EntityId | EntitySlug, type: EntityType = DEFAULT_ENTITY_TYPE, language: EntityLanguage = DEFAULT_ENTITY_LANGUAGE) => createSelector(
	getPostEntities,
	(posts) => posts[generateEntityId([ idOrSlug, type, language ])]
);

export const getPostLoaderState = (idOrSlug: EntityId | EntitySlug, type: EntityType = DEFAULT_ENTITY_TYPE, language: EntityLanguage = DEFAULT_ENTITY_LANGUAGE) => createSelector(
	getPost(idOrSlug, type, language),
	post => (post ? post : fromPost.initialPostState)._loaderState
);


export const arePostsLoaded = (collection: string) => createSelector(
	getPostsLoaderState(collection),
	(_loaderState: LoaderState) => _loaderState.loaded
);

export const arePostsLoading = (collection: string) => createSelector(
	getPostsLoaderState(collection),
	(_loaderState: LoaderState) => _loaderState.loading
);

export const arePostsLoadedOrLoading = (collection: string) => createSelector(
	getPostsLoaderState(collection),
	(_loaderState: LoaderState) => _loaderState.loaded || _loaderState.loading
);

export const arePostsFailed = (collection: string) => createSelector(
	getPostsLoaderState(collection),
	(_loaderState: LoaderState) => _loaderState.failed
);


export const isPostLoadedorLoading = (idOrSlug: EntityId | EntitySlug, type: EntityType = DEFAULT_ENTITY_TYPE, language: EntityLanguage = DEFAULT_ENTITY_LANGUAGE) => createSelector(
	getPostLoaderState(idOrSlug, type, language),
	(_loaderState: LoaderState) => _loaderState.loaded || _loaderState.loading
);

