import { Action } from '@ngrx/store';

import { Site } from '../../models/site.model';

export const SITE_LOAD = '[Site] load';
export const SITE_LOAD_SUCCESS = '[Site] load success';
export const SITE_LOAD_FAILURE = '[Site] load failure';

export class SiteLoadAction implements Action {
	public readonly type = SITE_LOAD;
}

export class SiteLoadSuccessAction implements Action {
	public readonly type = SITE_LOAD_SUCCESS;
	constructor(public payload: Site) {}
}

export class SiteLoadFailureAction implements Action {
	public readonly type = SITE_LOAD_FAILURE;
}

export type SiteActions
	= SiteLoadAction
	| SiteLoadSuccessAction
	| SiteLoadFailureAction;
