<nav [class.scrolled]="scroll" class="navbar fixed-top navbar-expand-xl navbar-sio">
	<div class="container-fluid" style="width: 100%">
		<a class="navbar-brand" routerLink="/" [inlineSVG]="'/assets/img/logo.svg'"></a>
		<button class="navbar-toggler hamburger hamburger--squeeze" type="button" (click)="isNavbarCollapsed = !isNavbarCollapsed" aria-controls="navbarContent" [attr.aria-expanded]="!isNavbarCollapsed" aria-label="Toggle navigation">
			<span class="hamburger-box">
				<span class="hamburger-inner"></span>
			</span>
		</button>
		<div [ngbCollapse]="isNavbarCollapsed" class="collapse navbar-collapse" id="navbarContent">
			<ul class="navbar-nav mx-auto">
				<!--<li class="nav-item">
					<a routerLink="/koszonjuk-sio" routerLinkActive="active" class="nav-link">
						Köszönjük, SIÓ!
					</a>
				</li>-->
				<li class="nav-item">
					<a routerLink="/termekek" routerLinkActive="active" class="nav-link">
						Termékeink
					</a>
				</li>
				<li class="nav-item">
					<a routerLink="/a-termeszet-es-mi" routerLinkActive="active" class="nav-link">
						A természet és mi
					</a>
				</li>
				<li class="nav-item">
					<a routerLink="/mehek-es-a-sio" routerLinkActive="active" class="nav-link">
						Méhek és a Sió
					</a>
				</li>
				<li class="nav-item">
					<a routerLink="/gyumolcsvilag" routerLinkActive="active" class="nav-link">
						Gyümölcsvilág
					</a>
				</li>
				<li class="nav-item">
					<a routerLink="/koszonjuk-sio" class="nav-link">
						Köszönjük, SIÓ!
					</a>
				</li>
				<li class="nav-item nested">
					<a nohref (click)="showContactModal()" class="nav-link">
						Kapcsolat
					</a>
					<hr class="menu-divider">
					<a routerLink="/tamogatas" routerLinkActive="active" class="nav-link">
						Támogatás és adományozás
					</a>
				</li>
			</ul>
			<span class="navbar-text">
				<a href="https://www.facebook.com/SioGyumolcs" target="_blank">
					<fa-layers [fixedWidth]="true" size="2x">
						<fa-icon [icon]="['fas', 'circle']"></fa-icon>
						<fa-icon [inverse]="true" [icon]="['fab', 'facebook-f']" transform="shrink-6"></fa-icon>
					</fa-layers>
				</a>
				<a href="https://www.instagram.com/sio_gyumolcs" target="_blank">
					<fa-layers [fixedWidth]="true" size="2x">
						<fa-icon [icon]="['fas', 'circle']"></fa-icon>
						<fa-icon [inverse]="true" [icon]="['fab', 'instagram']" transform="shrink-6"></fa-icon>
					</fa-layers>
				</a>
				<a href="https://www.youtube.com/user/Mindenunkagyumolcs" target="_blank">
					<fa-layers [fixedWidth]="true" size="2x">
						<fa-icon [icon]="['fas', 'circle']"></fa-icon>
						<fa-icon [inverse]="true" [icon]="['fab', 'youtube']" transform="shrink-6"></fa-icon>
					</fa-layers>
				</a>
			</span>
		</div>
	</div>
</nav>
