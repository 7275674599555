/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./footer.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./footer.component";
var styles_FooterComponent = [i0.styles];
var RenderType_FooterComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FooterComponent, data: {} });
export { RenderType_FooterComponent as RenderType_FooterComponent };
export function View_FooterComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "container align-items-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" \u00A9 2014-2022 SI\u00D3-ECKES Kft. Minden jog fenntartva. "])), (_l()(), i1.ɵeld(3, 0, null, null, 6, "nav", [["class", "nav ml-auto"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "a", [["class", "nav-link"], ["href", "/assets/pdf/adatkezelesi_tajekoztato.pdf"], ["target", "_blank"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Adatkezel\u00E9si t\u00E1j\u00E9koztat\u00F3"])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "a", [["class", "nav-link"], ["href", "/assets/pdf/jogi_nyilatkozat.pdf"], ["target", "_blank"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Jogi nyilatkozat"])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "a", [["class", "nav-link"], ["href", "/assets/pdf/cookie_tajekoztato.pdf"], ["target", "_blank"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Cookie t\u00E1j\u00E9koztat\u00F3"]))], null, null); }
export function View_FooterComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "footer", [], null, null, null, View_FooterComponent_0, RenderType_FooterComponent)), i1.ɵdid(1, 49152, null, 0, i2.FooterComponent, [], null, null)], null, null); }
var FooterComponentNgFactory = i1.ɵccf("footer", i2.FooterComponent, View_FooterComponent_Host_0, {}, {}, []);
export { FooterComponentNgFactory as FooterComponentNgFactory };
