export type Dictionary<T> = {
	[key: string]: T;
};

export type DictionaryNum<T> = {
	[key: number]: T;
};

export type NumberOrStringOrArrayOfNumberOrString = number | string | (number | string)[];

export type EntityGeneratedId = string;

export type EntityId = number | string;
export type EntitySlug = string;

export type EntityType = '-' | string;
export const DEFAULT_ENTITY_TYPE = '-';

export type EntityLanguage = '-' | string;
export const DEFAULT_ENTITY_LANGUAGE = '-';

export type EntityRevision = number;
export const DEFAULT_ENTITY_REVISION = '-';

export type EntityIdTuple = [
	EntityId | EntitySlug, EntityType?, EntityLanguage?, EntityRevision?
];

export interface Entity {
	// __ID?: EntityGeneratedId;

	id?: EntityId;
	slug?: EntitySlug;

	type?: EntityType;
	language?: EntityLanguage;
	revision?: EntityRevision;

	[key: string]: any;
}

export interface EntityWithSlug extends Entity {
	slug: EntitySlug;
}

export interface EntityWithId extends Entity  {
	id: EntityId;
}

export type PartialEntity<T extends Entity> =
	Partial<T>
	&
	Pick<T, keyof Entity>
;

export function isEntity(arg: any): arg is Entity {
	return arg &&
		(
			(arg.slug && (typeof(arg.slug) === 'string'))
			||
			(arg.id && (typeof(arg.id) === 'number' || typeof(arg.id) === 'string'))
		);
}

export function generateEntityId<T extends Entity>(entity: T): EntityGeneratedId;
export function generateEntityId(entity: EntityIdTuple): EntityGeneratedId;
export function generateEntityId(arg: any): EntityGeneratedId {
	if (isEntity(arg)) {
		if (typeof arg.slug === 'undefined' && typeof arg.id === 'undefined') throw new Error('Post object must have either slug or id property!');
		return `${arg.id || arg.slug}:${arg.type || DEFAULT_ENTITY_TYPE}:${arg.language || DEFAULT_ENTITY_LANGUAGE}:${arg.revision || DEFAULT_ENTITY_REVISION}`;
	} else if (arg instanceof Array) {
		return `${arg[0]}:${arg[1] || DEFAULT_ENTITY_TYPE}:${arg[2] || DEFAULT_ENTITY_LANGUAGE}:${arg[3] || DEFAULT_ENTITY_REVISION}`;
	}
}


export interface Term {
	id?: number;
	slug: string;
	name: string;
	mainRoute: string;
	description?: string;
}

export interface Pagination {
	total: number;
	current_page: number;
	last_page: number;
	per_page: number;
	from: number;
	to: number;
}
export type PageNum = number | 'next' | 'prev';
export const PAGE_NEXT = 'next';
export const PAGE_PREV = 'prev';


export interface EntityCollection {
	pages: DictionaryNum<EntityGeneratedId[]>;
	loadedEntities: EntityGeneratedId[];
	filter?: Filter;
	pagination?: Pagination;
	_loaderState?: LoaderState;
	_pagesLoaded?: number;
}
export type EntityCollections = Dictionary<EntityCollection>;
export const DEFAULT_ENTITY_COLLECTION = '_default';


export interface Filter {
	id?: EntityId | EntityId[];
	slug?: EntitySlug | EntitySlug[];
	type?: EntityType | EntityType[];
	language?: EntityLanguage | EntityLanguage[];
	revision?: EntityRevision | EntityRevision[];

	category?: string | string[];
	tag?: string | string[];

	[key: string]: any; // boolean | number | string | (boolean | number | string)[]
}


export interface LoaderState {
	loading: boolean;
	loaded: boolean;
	failed: boolean;
	partiallyLoaded?: boolean;
}
