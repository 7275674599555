/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./404.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@fortawesome/angular-fontawesome/angular-fontawesome.ngfactory";
import * as i3 from "@fortawesome/angular-fontawesome";
import * as i4 from "@angular/platform-browser";
import * as i5 from "@angular/router";
import * as i6 from "@angular/common";
import * as i7 from "./404.component";
import * as i8 from "../../../core/services/shared";
var styles_NotFoundComponent = [i0.styles];
var RenderType_NotFoundComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NotFoundComponent, data: {} });
export { RenderType_NotFoundComponent as RenderType_NotFoundComponent };
export function View_NotFoundComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h1", [["class", "title-404"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["404"])), (_l()(), i1.ɵeld(3, 0, null, null, 3, "p", [], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "fa-icon", [["class", "ng-fa-icon"], ["size", "4x"]], [[1, "title", 0], [8, "innerHTML", 1]], null, null, i2.View_FaIconComponent_0, i2.RenderType_FaIconComponent)), i1.ɵdid(5, 573440, null, 0, i3.FaIconComponent, [i4.DomSanitizer, i3.FaConfig, i3.FaIconLibrary, [2, i3.FaStackItemSizeDirective]], { icon: [0, "icon"], size: [1, "size"] }, null), i1.ɵpad(6, 2), (_l()(), i1.ɵeld(7, 0, null, null, 6, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Az \u00D6n \u00E1ltal keresett aloldal nem tal\u00E1lhat\u00F3, "])), (_l()(), i1.ɵeld(9, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["vagy megv\u00E1ltozott az el\u00E9r\u00E9se."])), (_l()(), i1.ɵeld(11, 0, null, null, 2, "a", [["routerLink", "/"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 12).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(12, 671744, null, 0, i5.RouterLinkWithHref, [i5.Router, i5.ActivatedRoute, i6.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, ["Tov\u00E1bb a f\u0151oldalra \u00BB"]))], function (_ck, _v) { var currVal_2 = _ck(_v, 6, 0, "fal", "sad-tear"); var currVal_3 = "4x"; _ck(_v, 5, 0, currVal_2, currVal_3); var currVal_6 = "/"; _ck(_v, 12, 0, currVal_6); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 5).title; var currVal_1 = i1.ɵnov(_v, 5).renderedIconHTML; _ck(_v, 4, 0, currVal_0, currVal_1); var currVal_4 = i1.ɵnov(_v, 12).target; var currVal_5 = i1.ɵnov(_v, 12).href; _ck(_v, 11, 0, currVal_4, currVal_5); }); }
export function View_NotFoundComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "section", [["page-404", ""]], null, null, null, View_NotFoundComponent_0, RenderType_NotFoundComponent)), i1.ɵdid(1, 49152, null, 0, i7.NotFoundComponent, [i8.SharedStore], null, null)], null, null); }
var NotFoundComponentNgFactory = i1.ɵccf("section[page-404]", i7.NotFoundComponent, View_NotFoundComponent_Host_0, {}, {}, []);
export { NotFoundComponentNgFactory as NotFoundComponentNgFactory };
