import { createSelector } from '@ngrx/store';

import { Site, Options, Categories, Tags, Menus } from '../../models/site.model';

import * as fromCore from '../reducers';
import * as fromSite from '../reducers/site.reducers';


const getConfig = (state: fromSite.State) => state.config;
const isLoading = (state: fromSite.State) => state.loading;
const isLoaded = (state: fromSite.State) => state.loaded;
const isLoadedOrLoading = (state: fromSite.State) => state.loaded || state.loading;
const isFailed = (state: fromSite.State) => state.failed;

export const getSiteConfig = createSelector(fromCore.getSiteState, getConfig);

export const getSiteConfigGroup = <T>(group: string) => createSelector(
	getSiteConfig,
	(getSiteConfig: Site) => getSiteConfig[group] as T
);

export const getMenuConfig = (location: string) => createSelector(
	getSiteConfigGroup<Menus>('menus'),
	(menus: Menus) => (menus.find(menu => menu.location === location) || { items: [] }).items
);

export const getTags = getSiteConfigGroup<Tags>('tags');
export const getCategories = getSiteConfigGroup<Categories>('categories');
export const getOptions = getSiteConfigGroup<Options>('options');

export const isSiteLoading = createSelector(fromCore.getSiteState, isLoading);
export const isSiteLoaded = createSelector(fromCore.getSiteState, isLoaded);
export const isSiteFailed = createSelector(fromCore.getSiteState, isFailed);
export const isSiteLoadedOrLoading = createSelector(fromCore.getSiteState, isLoadedOrLoading);

