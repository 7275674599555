import { Site } from '../../models/site.model';
import * as fromSite from '../actions/site.actions';

export interface State {
	loading: boolean;
	loaded: boolean;
	failed: boolean;
	config: Site;
}

const initialState: State = {
	loading: false,
	loaded: false,
	failed: false,
	config: {
		options: {},
		tags: [],
		categories: [],
		menus: [],
	},
};


export function reducer(state = initialState, action: fromSite.SiteActions): State {
	if (!action) {
		return state;
	}

	switch (action.type) {

		case fromSite.SITE_LOAD:
			return {
				...state,
				loading: true,
			};

		case fromSite.SITE_LOAD_SUCCESS:
			return {
				...state,
				config: action.payload,
				loading: false,
				loaded: true,
				failed: false,
			};

		case fromSite.SITE_LOAD_FAILURE:
			return {
				...state,
				loading: false,
				failed: true,
			};

		default:
			return state;
	}
}
