import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { CORE_COMPONENTS, CORE_ENTRY_COMPONENTS } from './components';
import { CORE_DIRECTIVES } from './directives';
import { SharedModule } from '@app/shared/shared.module';

import { COOKIE_CONSENT_CONFIG } from './configs/cookie-consent.config';
import { NGRX_CONFIG } from './configs/ngrx.config';
import { Angulartics2Module } from 'angulartics2';


@NgModule({
	imports: [
		CommonModule,
		RouterModule,
		ReactiveFormsModule,

		Angulartics2Module.forRoot(),

		...NGRX_CONFIG,
		...COOKIE_CONSENT_CONFIG,

		SharedModule,
	],
	declarations: [
		...CORE_COMPONENTS,
		...CORE_DIRECTIVES,
	],
	entryComponents: [
		...CORE_ENTRY_COMPONENTS
	],
	exports: [
		...CORE_COMPONENTS,
		...CORE_DIRECTIVES,
	],
	providers: [
	]
})
export class CoreModule {
	constructor(
		@Optional() @SkipSelf() parentModule: CoreModule,
	) {
		if (parentModule) {
			throw new Error('CoreModule has already been loaded. Import Core modules in the AppModule only.');
		}
	}
}
