import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap, pluck } from 'rxjs/operators';

import { Site } from '../models/site.model';
import { environment } from '@app-env/environment';


@Injectable()
export class SiteAPIService {

	private readonly endpoint = environment.API_URL + 'site/data';

	constructor(private http: HttpClient) {}

	public getSite(): Observable<Site> {
		const endpoint = this.endpoint;
		console.log(`XHR to ${endpoint} sent.`);
		return this.http.get<{data: Site}>(endpoint).pipe(
			tap(response => console.log(`XHR to ${endpoint} finished.`)),
			pluck('data'),
		);
	}
}
