export const environment = {
	production: false,
	hmr: false,

	APP_ENV: 'master',
	COMMIT_HASH: '52ce6d2e1d1d5aa4cf53886439f26b62d8c8591d',
	API_URL: '/api/',
	NCP_API_URL: 'http://sio.localhost:8080/api/',
	SERVICE_SENTRY_DSN_PUBLIC: null,
	SERVICE_RECAPTCHA_KEY: null,
};
