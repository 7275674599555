import { Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';
import * as fromCMS from '../store';
import { Injectable } from '@angular/core';
import { MenuItem, Options, Tag, Category } from '../models/site.model';
import { tap, filter, take, first } from 'rxjs/operators';
import { MetaService } from '@app/core/services/meta';
import { Router } from '@angular/router';
import { Filter, PAGE_NEXT, PAGE_PREV, PageNum, DEFAULT_ENTITY_COLLECTION, LoaderState, Dictionary, DEFAULT_ENTITY_TYPE, EntityType, Pagination } from '../models/entity.model';
// import { CMSModule } from '../cms.module';

export type GetPostOptions = {
	setMeta?: boolean;
	onError?: () => any;
};

export type GetPostsOptions = {
	filter?: Filter;
	page?: number;
	count?: number;
	forceReload?: boolean;
	loadPostsImmediately?: boolean;
	onError?: () => any;
};

export const RELOAD_COLLECTION = true;

// @Injectable({
// 	providedIn: CMSModule
// })
@Injectable()
export class CMSService {

	constructor(
		private _store: Store<fromCMS.State>,
		private _meta: MetaService,
		private _router: Router
	) {}


	// SITE

	private _loadSite(): void {
		this._store.select(fromCMS.isSiteLoadedOrLoading).pipe(
			take(1)
		).subscribe(
			isLoadedOrLoading => {
				if (!isLoadedOrLoading) {
					this._store.dispatch(new fromCMS.SiteLoadAction());
				}
			},
		);
	}

	public getSiteConfigGroup(group: string): Observable<any> {
		this._loadSite();
		return this._store.select(fromCMS.getSiteConfigGroup(group));
	}

	public getOptions(): Observable<Options> {
		this._loadSite();
		return this._store.select(fromCMS.getOptions);
	}

	public getMenu(location: string): Observable<MenuItem[]> {
		this._loadSite();
		return this._store.select(fromCMS.getMenuConfig(location));
	}

	public getTags(): Observable<Tag[]> {
		this._loadSite();
		return this._store.select(fromCMS.getTags);
	}

	public getCategories(): Observable<Category[]> {
		this._loadSite();
		return this._store.pipe(
			select(fromCMS.getCategories),
			filter(categories => categories.length > 0),
		);
	}


	// POSTS

	private async _loadPosts(collection: string, page: PageNum = PAGE_NEXT, count?: number) {
		// console.log('CMS _loadPosts', collection, page);
		if (page === PAGE_NEXT || page === PAGE_PREV) {
			let current_page = 0;
			this._store.select(fromCMS.getPostsPagination(collection)).pipe(first()).subscribe(pagination => {
				if (typeof pagination !== 'undefined') {
					current_page = pagination.current_page;
				}
			});
			if (page === PAGE_NEXT) page = current_page + 1;
			if (page === PAGE_PREV) page = current_page - 1;
		}
		this._store.dispatch(new fromCMS.PostsLoadAction({ collection, page, count }));
	}

	public loadPosts(collection: string = DEFAULT_ENTITY_COLLECTION, page: PageNum = PAGE_NEXT): void {
		// console.log('CMS loadPostCollection', collection, page);
		this._loadPosts(collection, page);
	}

	public setPostsFilter(collection: string = DEFAULT_ENTITY_COLLECTION, filter: Filter = {}, updateCollection: boolean = false): void {
		// console.log('CMS setPostCollectionFilter', collection, filter, updateCollection);
		this._store.dispatch(new fromCMS.PostsSetFilterAction({ collection, filter }));
		if (updateCollection) this.loadPosts(collection, 1);
	}

	public getPosts(collection: string = DEFAULT_ENTITY_COLLECTION, page: PageNum = PAGE_NEXT, options?: GetPostsOptions): Observable<fromCMS.PostState[]> {
		// console.log('CMS getPosts', collection, options);
		options = { loadPostsImmediately: false, forceReload: false, ...options };

		let shouldLoad = options.forceReload;
		this._store.select(fromCMS.getPostsLoaderState(collection)).pipe(first()).subscribe(loaderState => {
			shouldLoad = shouldLoad || (options.loadPostsImmediately && (loaderState.failed || !(loaderState.loading || loaderState.loaded)));
		});

		if (shouldLoad) {
			if (options.filter) this.setPostsFilter(collection, options.filter);
			this.loadPosts(collection, page);
		}

		return this._store.select(fromCMS.getPosts(collection));
	}

	public getPostsLoaderState(collection: string = DEFAULT_ENTITY_COLLECTION): Observable<LoaderState> {
		return this._store.select(fromCMS.getPostsLoaderState(collection));
	}

	public getPostsPagination(collection: string = DEFAULT_ENTITY_COLLECTION): Observable<Pagination> {
		return this._store.select(fromCMS.getPostsPagination(collection));
	}

	// POST

	private async _loadPost(slug: string, filter: Filter, options?: Dictionary<string>) {
		const type = Array.isArray(filter.type) ? filter.type[0] : filter.type;
		const language = (Array.isArray(filter.language) ? filter.language[0] : filter.language) || undefined;
		if (!await this._store.select(fromCMS.isPostLoadedorLoading(slug, type, language)).pipe(first()).toPromise()) {
			this._store.dispatch(new fromCMS.PostLoadAction({ slug, type, language, options }));
		}
	}

	public getPost(slug: string, type: EntityType, options?: Dictionary<string>): Observable<fromCMS.PostState>;
	public getPost(slug: string, filter: Filter, options?: Dictionary<string>): Observable<fromCMS.PostState>;
	public getPost(slug: string, arg: any, options: Dictionary<string> = {}): Observable<fromCMS.PostState> {
		let entityFilter: Filter = { type: DEFAULT_ENTITY_TYPE };
		if (typeof arg === 'string') {
			entityFilter = { ...entityFilter, type: arg as EntityType };
		} else if (typeof arg === 'object') {
			entityFilter = { ...entityFilter, ...arg };
		}
		this._loadPost(slug, entityFilter, options);

		const type = Array.isArray(entityFilter.type) ? entityFilter.type[0] : entityFilter.type;
		const language = (Array.isArray(entityFilter.language) ? entityFilter.language[0] : entityFilter.language) || undefined;

		return this._store.pipe(
			select(fromCMS.getPost(slug, type, language)),
			filter(post => typeof post !== 'undefined'),
			// tap(post => {
			// 	if (post._loaderState.failed) {
			// 		console.error('post load failed');
			// 		if (typeof options.onError === 'function') options.onError();
			// 		// this._router.navigate(['hiba']);
			// 		return;
			// 	}
			// 	if (post._loaderState.loaded) {
			// 		if (options && options.setMeta) {
			// 			this._meta.setMeta(post);
			// 		}
			// 	}
			// }),
			// tag(`post:${slug}`),
		);
	}
}
