<div class="container">
	<div class="row">
		<!-- <div class="col-md-6">
			<h3>Hírlevél</h3>
			<p>Ne maradj le gyümölcsös újdonságainkról!<br> Iratkozz fel hírlevelünkre!</p>
			<form [formGroup]="newsletterForm" (submit)="sendNewsletter()" novalidate>
				<div class="input-group">
					<input type="email" formControlName="email" class="form-control" placeholder="Email cím" required>
					<div class="input-group-append">
						<button type="submit" [disabled]="newsletterForm.invalid || formProgress" class="btn btn-green">Feliratkozom</button>
					</div>
				</div>
			</form>
		</div> -->
		<div class="col-md-6">
			<h3>Kövess Minket!</h3>
			<p>Kövess minket, hogy elsőként értesülhess a SIÓ híreiről!</p>
			<p class="icons">
				<a href="https://www.facebook.com/SioGyumolcs" target="_blank">
					<fa-layers [fixedWidth]="true" size="2x">
						<fa-icon [icon]="['fas', 'circle']"></fa-icon>
						<fa-icon [inverse]="true" [icon]="['fab', 'facebook-f']" transform="shrink-6"></fa-icon>
					</fa-layers>
				</a>
				<a href="https://www.instagram.com/sio_gyumolcs" target="_blank">
					<fa-layers [fixedWidth]="true" size="2x">
						<fa-icon [icon]="['fas', 'circle']"></fa-icon>
						<fa-icon [inverse]="true" [icon]="['fab', 'instagram']" transform="shrink-6"></fa-icon>
					</fa-layers>
				</a>
				<a href="https://www.youtube.com/user/Mindenunkagyumolcs" target="_blank">
					<fa-layers [fixedWidth]="true" size="2x">
						<fa-icon [icon]="['fas', 'circle']"></fa-icon>
						<fa-icon [inverse]="true" [icon]="['fab', 'youtube']" transform="shrink-6"></fa-icon>
					</fa-layers>
				</a>
			</p>
		</div>
	</div>
</div>
