import { Meta, Title, MetaDefinition } from '@angular/platform-browser';
import { Injectable } from '@angular/core';
// import { isResourceWithSEOData } from '@app/cms/models/wp-resource.model';

@Injectable({
	providedIn: 'root',
})
export class MetaService {

	constructor(private title: Title, private meta: Meta) {}

	public setMeta(state: any): void {
		const meta: MetaDefinition[] = [];
		// if (isResourceWithSEOData(state)) {
		// 	Object.entries(state.fields.seo).map(([key, value]) => {
		// 		if (value !== null) {
		// 			switch (key) {
		// 				case 'title': {
		// 					this.title.setTitle(value);
		// 					break;
		// 				}
		// 				default: {
		// 					meta.push(
		// 						{
		// 							name: key,
		// 							content: value
		// 						}
		// 					);
		// 				}
		// 			}
		// 			meta.push({
		// 				property: 'og:' + key,
		// 				content: value
		// 			});
		// 		}
		// 	});
		// 	meta.push({
		// 		property: 'og:url',
		// 		content: 'https://eurocenterobuda.hu' + state.canonical_url
		// 	});
		// 	// console.log('setMeta', meta);
		// 	meta.forEach(tag => this.meta.updateTag(tag));
		// }
	}
}
