import { NotFoundComponent } from './404/404.component';
import { ErrorComponent } from './error/error.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { TopFooterComponent } from './top-footer/top-footer.component';

export const SHARED_ENTRY_COMPONENTS = [
];

export const SHARED_COMPONENTS = [
	...SHARED_ENTRY_COMPONENTS,
	HeaderComponent,
	FooterComponent,
	TopFooterComponent,
	NotFoundComponent,
	ErrorComponent,
];

export * from './header/header.component';
export * from './footer/footer.component';
export * from './top-footer/top-footer.component';
export * from './404/404.component';
export * from './error/error.component';
