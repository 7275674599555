import { Component } from '@angular/core';

import { SharedStore } from '@app-core/services/shared';


@Component({
	selector:		'section[page-404]',
	styleUrls:		[ '404.component.scss' ],
	templateUrl:	'404.component.html',
})
export class NotFoundComponent {
	constructor(
		public shared: SharedStore,
	) {
		this.shared.page = '404';
	}
}
