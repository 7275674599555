import { NgModule } from '@angular/core';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { reducers, effects } from './store';

import { SiteAPIService } from './services/site.service';
import { PostsAPIService } from './services/post.service';

import { CMSService } from './services/cms.service';


@NgModule({
	imports: [
		StoreModule.forFeature(
			'cms', reducers
		),
		EffectsModule.forFeature(
			effects
		),
	],
	declarations: [
	],
	entryComponents: [
	],
	exports: [
	],
	providers: [
		SiteAPIService,
		PostsAPIService,

		CMSService,
	]
})
export class CMSModule {}
