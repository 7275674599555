import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class SharedStore {
	public title: string;
	public page: string;
	public state: string = 'ap';
	public email = new Subject<string>();
}
