import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap, pluck } from 'rxjs/operators';

import { environment } from '@app-env/environment';
import { Dictionary } from '@ngrx/entity';
import { PartialEntity, Filter, EntityWithSlug, Pagination, EntityType, EntityLanguage, DEFAULT_ENTITY_TYPE } from '../models/entity.model';


@Injectable()
export class PostsAPIService {

	private readonly endpoint = environment.API_URL + 'posts';

	constructor(private http: HttpClient) {}

	private _filterToHttpParams(filter: Filter, paramsIn?: HttpParams): HttpParams {
		let params = paramsIn instanceof HttpParams ? paramsIn : new HttpParams();

		for (const [key, value] of Object.entries(filter)) {
			if (Array.isArray(value) && value.length > 0) {
				params = params.set(key, value.join(','));
			} else if (typeof value === 'string') {
				params = params.set(key, value);
			} else if (typeof value === 'number') {
				params = params.set(key, value.toString());
			}
		}

		return params;
	}

	public getPosts<T extends EntityWithSlug>(options: { filter?: Filter, page?: number, count?: number} = {}): Observable<{ items: PartialEntity<T>[], pagination: Pagination}> {
		// console.log('PostsAPIService.getPosts', options);
		const endpoint = this.endpoint;
		let params = new HttpParams();
		if (options.filter) params = this._filterToHttpParams(options.filter, params);

		if (typeof options.page === 'number') {
			params = params.set('page', options.page.toString());
		}
		if (typeof options.count === 'number') {
			params = params.set('count', options.count.toString());
		}
		console.log(`XHR to ${endpoint} sent.`, params.toString());
		return this.http.get<{data: { items: PartialEntity<T>[], pagination: Pagination }}>(endpoint, { params }).pipe(
			tap(response => console.log(`XHR to ${endpoint} finished.`)),
			pluck('data'),
			// map(response => ({ items: response.data.items, pagination: {} })),
		);
	}

	public getPost<T extends EntityWithSlug>(slug: string): Observable<PartialEntity<T>>;
	public getPost<T extends EntityWithSlug>(slug: string, type?: EntityType, language?: EntityLanguage, options?: Dictionary<string>): Observable<PartialEntity<T>>;
	public getPost<T extends EntityWithSlug>(slug: string, filter: Filter, options?: Dictionary<string>): Observable<PartialEntity<T>>;
	public getPost<T extends EntityWithSlug>(slug: string, ...args: any): Observable<PartialEntity<T>> {

		const endpoint = this.endpoint + '/' + slug;

		let filter: Filter = { type: DEFAULT_ENTITY_TYPE };
		let options: Dictionary<string> = {};

		if (typeof args[0] === 'undefined') {
			filter = { type: DEFAULT_ENTITY_TYPE };
		} else if (typeof args[0] === 'string') {
			filter = { ...filter, type: args[0] as EntityType };
		} else if (typeof args[0] === 'object') {
			filter = { ...filter, ...args[0] };
		}

		if (typeof args[1] === 'string') {
			filter = { ...filter, language: args[1] as EntityLanguage };
		} else if (typeof args[1] === 'object') {
			options = { ...options, ...args[1] };
		}

		if (typeof args[2] === 'object') {
			options = { ...options, ...args[2] };
		}

		let params = new HttpParams();
		params = this._filterToHttpParams(filter, params);
		if (options && options.preview) params = params.set('preview', options.preview);
		console.log(`XHR to ${endpoint} sent.`, params.toString());
		return this.http.get<{data: PartialEntity<T>}>(endpoint, { params }).pipe(
			tap(response => console.log(`XHR to ${endpoint} finished.`)),
			pluck('data'),
		);
	}
}
