import { Component, OnInit, OnDestroy } from '@angular/core';
import { PlatformLocation } from '@angular/common';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { FileUploadValidators } from '@iplab/ngx-file-upload';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from '@app-env/environment';
import { tap, pluck } from 'rxjs/operators';
import { ErrorService } from '@app/core/services/error';
import { MessageModalComponent } from '../message/message.component';

@Component({
	selector:		'modal-contact',
	templateUrl:	'contact.component.html',
	styleUrls:		['contact.component.scss'],
})
export class ContactModalComponent implements OnInit, OnDestroy {

	public contactForm = new FormGroup({
		name: new FormControl('', [
			Validators.required
		]),
		email: new FormControl('', [
			Validators.email,
			Validators.required
		]),
		type: new FormControl(null, [
			Validators.required
		]),
		text: new FormControl('', [
			Validators.required
		]),
		files: new FormControl(null,
			FileUploadValidators.filesLimit(5)
		),
		accept_terms: new FormControl(false, [
			Validators.requiredTrue
		])
	});

	public errorMessages: string[] = [];

	public submitDisabled = false;

	constructor(
		public activeModal: NgbActiveModal,
		private _location: PlatformLocation,
		private _http: HttpClient,
		private _modalService: NgbModal,
		public errorProvider: ErrorService,
	) {
	}

	public ngOnInit() {
		this._location.onPopState(() => this.activeModal.dismiss());
		// this._locationSubscription = this.location.subscribe(x => {
		// 	console.log(x);
		// 	this.activeModal.close();
		// });
	}

	public ngOnDestroy(): void {
		// this._locationSubscription.unsubscribe();
	}

	public submitContactForm() {
		this.submitDisabled = true;
		this.errorMessages = [];

		const endpoint = environment.API_URL + 'send-mail';
		const formData: FormData = new FormData();

		for (const [field, value] of Object.entries(this.contactForm.value)) {
			if (value === null || value === undefined) continue;
			if (field === 'files' && Array.isArray(value)) {
				for (let i = 0; i < value.length; i++) {
					formData.append(`files[${i}]`, value[i], value[i].name);
				}
			} else if (typeof value === 'string' || typeof value === 'number') {
				formData.append(field, value.toString());
			} else {
				formData.append(field, JSON.stringify(value));
			}
		}

		console.log(`XHR to ${endpoint} sent.`, formData.toString());
		this._http.post(endpoint, formData).pipe(
			tap(response => console.log(`XHR to ${endpoint} finished.`)),
			pluck('data'),
		).subscribe(
			(result) => {
				console.log('send-mail request succeeded.', result);
				this.activeModal.close();
				this.showMessageModal({
					title: 'Kapcsolat',
					message: '<p>Köszönjük, hamarosan válaszolunk a levélre!</p>',
				});
				this.submitDisabled = false;
			},
			(response: HttpErrorResponse) => {
				console.warn('send-mail request rejected.', response);
				const errors = response.error.errors as any[];
				this.errorMessages = errors.map(error => this.errorProvider.get(error.code || error));
				// this.showMessageModal({
				// 	title: 'Hiba',
				// 	message: this.errorMessages.join('<br>'),
				// });
				this.submitDisabled = false;
			},
		);
	}

	public showMessageModal({
		title,
		message,
	}: {
		title: string,
		message: string,
	}): Promise<any> {
		const modal = this._modalService.open(MessageModalComponent);
		modal.componentInstance.title = title;
		modal.componentInstance.message = message;
		return modal.result;
	}

}
