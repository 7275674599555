import { MessageModalComponent } from './message/message.component';
import { ContactModalComponent } from './contact/contact.component';

export const MODAL_COMPONENTS = [
	MessageModalComponent,
	ContactModalComponent,
];

export * from './message/message.component';
export * from './contact/contact.component';
