<div class="modal-header">
	<h3 class="modal-title">{{ title }}</h3>
	<button *ngIf="options?.backdrop !== 'static'" type="button" class="btn close" (click)="activeModal.dismiss()">
		<fa-layers [fixedWidth]="true" size="2x">
			<fa-icon [icon]="['fas', 'circle']"></fa-icon>
			<fa-icon [inverse]="true" [icon]="['fal', 'times']" transform="shrink-6"></fa-icon>
		</fa-layers>
	</button>
</div>
<div class="modal-body" [innerHTML]="message" [id]="trackingId"></div>
<div class="modal_footer">
	<ng-container *ngIf="buttons">
		<p>
			<ng-container *ngFor="let button of buttons">
				<ng-container [ngSwitch]="button.type">
					<button *ngSwitchCase="'dismiss'" type="button" [ngClass]="button?.class" (click)="activeModal.dismiss(button.id)">{{ button.caption }}</button>
					<button *ngSwitchDefault type="button" [ngClass]="button?.class" (click)="activeModal.close(button.id)">{{ button.caption }}</button>
				</ng-container>
			</ng-container>
		</p>
	</ng-container>
	<p>
		<button *ngIf="options?.backdrop !== 'static'" type="button" class="btn btn-link" (click)="activeModal.close()">Bezár</button>
		<a *ngIf="options?.backdrop === 'static'" routerLink="/" class="btn btn-link" (click)="activeModal.close()">Bezár</a>
	</p>
</div>
