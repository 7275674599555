import * as tslib_1 from "tslib";
import { of, defer } from 'rxjs';
import { switchMap, catchError, map } from 'rxjs/operators';
import { Effect, ofType } from '@ngrx/effects';
import * as SiteActions from '../actions/site.actions';
// import * as Sentry from '@sentry/browser';
export class SiteEffects {
    constructor(actions$, siteService) {
        this.actions$ = actions$;
        this.siteService = siteService;
        this.loadSite$ = this.actions$.pipe(ofType(SiteActions.SITE_LOAD), switchMap(() => {
            return this.siteService.getSite().pipe(map(siteConfig => new SiteActions.SiteLoadSuccessAction(siteConfig)), catchError(error => {
                // Sentry.withScope(scope => {
                // 	scope.setTag('module', 'site');
                // 	scope.setExtra('error', error);
                // 	Sentry.captureMessage('CMS API error');
                // });
                return of(new SiteActions.SiteLoadFailureAction());
            }));
        }));
        this.init$ = defer(() => {
            console.log('SiteEffects: init$');
            return of(new SiteActions.SiteLoadAction());
        });
    }
}
tslib_1.__decorate([
    Effect()
], SiteEffects.prototype, "loadSite$", void 0);
tslib_1.__decorate([
    Effect()
], SiteEffects.prototype, "init$", void 0);
