import { ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromSite from './site.reducers';
import * as fromPost from './post.reducers';

export { State as SiteState } from './site.reducers';
export { State as PostsState, PostState } from './post.reducers';

export interface State {
	site: fromSite.State;
	posts: fromPost.State;
}

export const reducers: ActionReducerMap<State> = {
	site: fromSite.reducer,
	posts: fromPost.reducer,
};

const getState = createFeatureSelector<State>('cms');

export const getSiteState = createSelector(
	getState,
	(state: State) => state.site
);
export const getPostsState = createSelector(
	getState,
	(state: State) => state.posts
);
