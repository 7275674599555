import { Routes } from '@angular/router';
import { NotFoundComponent } from '@app/shared/components';
const ɵ0 = () => import("./ncp/ncp.module.ngfactory").then(m => m.NCPModuleNgFactory), ɵ1 = () => import("./home/home.module.ngfactory").then(m => m.HomeModuleNgFactory);
const appRoutes = [
    {
        path: 'nyeremenyjatek',
        // pathMatch: 'full',
        loadChildren: ɵ0,
    },
    {
        path: '',
        // pathMatch: 'full',
        loadChildren: ɵ1
    },
    {
        path: '**',
        component: NotFoundComponent,
    },
];
export class AppRoutingModule {
}
export { ɵ0, ɵ1 };
