<div class="modal-header">
	<h3 class="modal-title">Kapcsolat</h3>
	<button type="button" class="btn close" (click)="activeModal.dismiss()">
		<fa-layers [fixedWidth]="true" size="2x">
			<fa-icon [icon]="['fas', 'circle']"></fa-icon>
			<fa-icon [inverse]="true" [icon]="['fal', 'times']" transform="shrink-6"></fa-icon>
		</fa-layers>
	</button>
</div>
<div class="modal-body">
	<form [formGroup]="contactForm" (ngSubmit)="submitContactForm()" novalidate>
		<div class="form-group">
			<input type="text" name="name" formControlName="name" class="form-control" placeholder="Név">
		</div>
		<div class="form-group">
			<input type="email" name="email" formControlName="email" class="form-control" placeholder="Email">
		</div>
		<div class="form-group">
			<ng-select labelForId="type" formControlName="type" [searchable]="false" class="select-sio" placeholder="Megkeresés témája">
				<ng-option value="ertekesites">Értékesítés</ng-option>
				<ng-option value="termekpanasz">Termékpanasz</ng-option>
				<!-- <ng-option value="marketing">Marketing</ng-option> -->
				<ng-option value="sajtokapcsolat">Sajtókapcsolat</ng-option>
				<!-- <ng-option value="egyeb">Egyéb</ng-option> -->
				<ng-option value="nyeremenyjatek">Nyereményjáték</ng-option>
			</ng-select>
		</div>
		<div class="form-group">
			<textarea class="form-control" name="text" formControlName="text" rows="3" placeholder="Üzenet"></textarea>
		</div>
		<p><strong>Dokumentum:</strong></p>
		<file-upload formControlName="files" maxsize="5000 MB" accept="image/*,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document">
			<ng-template let-isFileDragDropAvailable="isFileDragDropAvailable" #placeholder>
				<div *ngIf="isFileDragDropAvailable"><fa-icon [icon]="['fal', 'upload']"></fa-icon> <span>Füzd ide a fájlt vagy klikk ide</span></div>
				<span *ngIf="!isFileDragDropAvailable">Klikk</span>
			</ng-template>
			<ng-template let-i="index" let-file="file" let-control="control" #item>
				<div class="file">
					<div class="file-info">
						<span class="remove-btn" (click)="control.removeFile(file)">
							<fa-icon [icon]="['fal', 'trash-alt']"></fa-icon>
						</span>
						<span class="file-name">{{ file.name }}</span>
					</div>
				</div>
			</ng-template>
		</file-upload>
		<p><small>Formátum: .jpg, .jpeg, .png, .pdf, .doc, .docx<br> Maxiumum méret: 5Mb</small></p>
		<div class="custom-control custom-checkbox">
			<input type="checkbox" name="accept_terms" formControlName="accept_terms" class="custom-control-input" id="accept_terms">
			<label class="custom-control-label" for="accept_terms">Hozzájárulok, hogy e-mail címemet a SIÓ ECKES Kft. a kapcsolatfelvétel céljából az <a href="/assets/pdf/adatkezelesi_tajekoztato.pdf" target="_blank">Adatkezelési tájékoztatóban</a> írtak szerint kezelje.</label>
		</div>
		<div *ngIf="errorMessages.length" class="alert alert-danger text-center">
			<p *ngFor="let error of errorMessages; let last = last" [class.mb-0]="last">{{ error }}</p>
		</div>
		<p>
			<button type="submit" [disabled]="contactForm.invalid || submitDisabled" class="btn btn-green">Küldés</button>
		</p>
	</form>
</div>
<div class="modal-footer">
	<button type="button" class="btn btn-link" (click)="activeModal.close()">Bezár</button>
</div>
